import React from "react";
import {
  Wrapper,
  WhatIsAvailableContainer,
  Heading,
  CardContainer,
  TextContainer,
  Card,
  Grade,
  ImageContainer,
  GradeSub
} from "./what-is-available.styles";
import UntukImg from "public/assets/home/available-classes/untuk-kelas.png";
import KurikulumImg from "public/assets/home/available-classes/Kurikulum Merdeka.png";
import MatematicaImg from "public/assets/home/available-classes/Mathematika.png";

const ParentComponent = ({ children }: { children: React.ReactNode }) => {
  return <CardContainer>{children}</CardContainer>;
};

export const WhatIsAvailableSection: React.FC = () => {
  return (
    <Wrapper>
      <WhatIsAvailableContainer>
        <Heading>Di Bimbel CoLearn, tersedia:</Heading>
        <ParentComponent>
          {availableGrades.map(({ img, grade, gradeSub }) => (
            <Card key={grade}>
              <ImageContainer
                src={img}
                alt={grade + gradeSub}
                layout="fill"
                objectFit="cover"
              />
              <TextContainer>
                <Grade grade={grade}>{grade}</Grade>
                <GradeSub gradeSub={gradeSub}>{gradeSub}</GradeSub>
              </TextContainer>
            </Card>
          ))}
        </ParentComponent>
      </WhatIsAvailableContainer>
    </Wrapper>
  );
};

const availableGrades = [
  {
    img: UntukImg,
    grade: "Untuk Kelas",
    gradeSub: "4 SD - 12 SMA"
  },
  {
    img: KurikulumImg,
    grade: "Kurikulum Merdeka",
    gradeSub: "Kurikulum 2013"
  },
  {
    img: MatematicaImg,
    grade: "Matematika",
    gradeSub: "Fisika, Kimia, IPA"
  }
];
